import {ref, watch, computed} from '@vue/composition-api'
import store from '@/store'

// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function priceactivityUseList() {
    // Use toast
    const toast = useToast()

    const refListTable = ref(null)

    // Table Handlers
    const tableColumns = [
        {key: 'aid', label: '编号', sortable: true},
        {key: 'code', label: '商品69码', sortable: true},
        {key: 'name', label: '商品名称', sortable: true},
        {key: 'type_id', label: '适用范围', sortable: true},
        {key: 'target_name', label: '销售平台/团队', sortable: true},
        {key: 'cost', label: '商品成本', sortable: true},
        // {key: 'guide_price', label: '销售指导价', sortable: true},
        {key: 'activity_price', label: '活动价格', sortable: true},
        {key: 'date', label: '活动有效期', sortable: true},
        {key: 'status', label: '活动状态', sortable: true},
        {key: 'memo', label: '备注', sortable: true},
        {key: 'add_time', label: '创建时间', sortable: true},
        {key: 'creator', label: '创建人', sortable: true},
        {key: 'actions', label: '操作'},
    ]

    const start = ref(1)
    const limit = ref(10)
    const limitOptions = [10, 25, 50, 100]
    const listTotals = ref(0)
    const searchKey = ref('')
    const orderBy = ref('aid')
    const isSortDirDesc = ref(true)
    const condition = ref({})
    const tabStatus = ref(1)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
            to: limit.value * (start.value - 1) + localItemsCount,
            of: listTotals.value,
        }
    })

    const refetchData = () => {
        refListTable.value.refresh()
    }

    watch([start, limit, searchKey, tabStatus], () => {
        refetchData()
    })

    const searchList = (ctx, callback) => {
        store
            .dispatch('priceactivity/search', {
                search_key: searchKey.value,
                start: start.value,
                limit: limit.value,
                order_by: orderBy.value,
                order_desc: isSortDirDesc.value === true ? 'desc' : '',
                status:tabStatus.value,
                name:condition.value.name,
                code:condition.value.code,
                aid_no:condition.value.aidNo,
            })
            .then(response => {
                const data = response.data.data
                const list = data.ext.list
                listTotals.value = data.ext.totals

                callback(list)
            })
            .catch((e) => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: '活动价格列表获取错误',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    return {
        searchList,
        tableColumns,
        limit,
        start,
        listTotals: listTotals,
        dataMeta,
        limitOptions,
        searchKey,
        orderBy,
        isSortDirDesc,
        refListTable,

        refetchData,
        condition,
        tabStatus,
        // Extra Filters
    }
}
